$theme-colors: (
  "primary": #00563c,
  "secondary": #fff0dc,
  "yellow": #fff379,
  "green": #bae8b9,
  "blue": #d0e4fc,
  "danger": red,
);
$primary: #00563c;
$secondary: #fff0dc;

body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  color: #00563c;
}

.sidebar .nav-link .feather {
}

.sidebar .nav-link.active {
  background: $primary;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */
.bg-primary {
  background-color: #00563c !important;
}
.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.card-img-top {
  max-width: 120px;
  align-self: center;
}

.card-img-top.reference {
  max-width: 600px;
  align-self: flex-start;
}

.btn.pitchichi {
  //width: 105px;
  border-radius: 10px;
  border: solid 2px;
}
.card-header-logo {
  height: 30%;
  width: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-header-team {
  height: 120px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.hidden-agency {
  height: 40px;
  width: 40px;
  position: absolute;
  background-repeat: no-repeat;
  left: 5px;
  top: 5px;
}

.btn.pitchichi2 {
  color: $primary;

  border-radius: 29px;
  border: solid 2px $primary;
}

.btn.dropdown-toggle {
  color: $primary;

  border-radius: 10px;
  border: solid 2px $primary;
}
.alert {
  position: absolute;
  top: 30%;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  z-index: 300;
  left: 30%;
}

.alert-pitchichi {
  position: absolute !important;
  left: 50%;
  transform: translate(-50%, 0);
}

.required-error {
  font-size: 12px;
}

.form-check-input {
  margin-top: 0.25em !important;
}

.editable {
  font-family: sans-serif;
  min-height: 50px;
  border: 1px solid #aaa;
  padding: 5px;
}

.suggestion {
  cursor: pointer;
  border: 1px;
  border-right: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}

.suggestion:hover {
  background-color: #a0c0b6;
}

@import "~bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
}

.wrapper-reset {
    height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-reset {
  width: 100%;
  max-width: 530px;
  padding: 15px;
  margin: auto;
}

.form-reset input[type="password1"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-reset input[type="password2"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
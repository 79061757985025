html,
body {
  height: 100%;
}

.wrapper {
    height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.spinner-emailconfirmation {
  position: absolute;
  left: 50%;
    top: 50%;
    color: #00563c;
}